import React from "react"
import Layout from "./layout"
import ScrollySection from "./explainer/ScrollySection"
import { motion } from "framer-motion"

export default () => (
  <Layout menuHidden={false} alternateMenu={false}>
    <motion.main animate="undefined" exit="undefined">
      <ScrollySection />
    </motion.main>
  </Layout>
)

import React from "react"
import {Helmet} from "react-helmet"
import Explainer from "../explainer"

export default () =>
<>
  <Helmet>
    <meta property="og:url" content="https://moondisaster.org/explainer"/>
    <meta property="og:title" content="In Event of Moon Disaster - Behind the Scenes"/>
    <meta property="og:description" content="How We Brought President Nixon Back to Life (And Killed the Astronauts of Apollo 11)"/>
    <meta property="og:image:secure_url" content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"/>
    <meta property="og:image:url" content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"/>
    <meta property="og:image" content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"/>
    <meta property="og:image:width" content="1200"/>
    <meta property="og:image:height" content="630"/>
    <meta property="og:image:alt" content="lunar landscape with project logo"/>
    <meta property="article:author:name" content="Francesca Panetta and Halsey Burgund"/>
    <meta name="twitter:title" content="In Event of Moon Disaster - Behind the Scenes"/>
    <meta name="twitter:description" content="How We Brought President Nixon Back to Life (And Killed the Astronauts of Apollo 11)"/>
    <meta name="twitter:creator" content="Francesca Panetta and Halsey Burgund"/>
    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:image" content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x446.jpg?p=1465"/>
    <meta name="twitter:image:alt" content="lunar landscape with project logo"/>
    <meta itemProp="name" content="In Event of Moon Disaster - Behind the Scenes"/>
    <meta itemProp="description" content="How We Brought President Nixon Back to Life (And Killed the Astronauts of Apollo 11)"/>
    <meta name="author" content="Francesca Panetta and Halsey Burgund"/>
    <meta name="description" content="How We Brought President Nixon Back to Life (And Killed the Astronauts of Apollo 11)"/>
    <meta name="thumbnail" content="https://moondisaster.space/wp-content/uploads/2020/02/AI_mapping-1200x630-cropped.jpg"/>
  </Helmet>
  <Explainer />
</>
